import React from 'react';
import { Link } from 'react-router-dom';
import { calculatePageHeight } from './Page';
import { isFirstTimeHome } from "./App";

const transitionTime = 500;
const viewTime = 1500;

export default class Home extends React.Component {
    componentDidMount() {
        document.title = "Zach Gateley - Personal Website";
        // Execute after page height 
        if (isFirstTimeHome()) {
            window.setTimeout(slideshowStart, transitionTime);
        }
        else {
            slideshowExit();
        }
    }

    render() {
        return (
        <div style={{ position: "relative", overflow: "hidden" }}>
            <div className="HomeItem">
                <div className="text-center display-3 p-5">
                    Hi, I'm Zach.
                </div>
            </div>
            <div className="HomeItem">
                <div className="text-center display-4 p-4">
                    I am driven by a passion for improvement, simplification, and accuracy.
                </div>
            </div>
            <div className="HomeItemAppend">
                <div className="p-2 pt-4">
                    <p>
                        I strive to deliver the best solutions for the task at hand. 
                    </p>
                    <p>
                        My expertise has allowed me to work in a large breadth of fields. I currently work in semiconductor manufacturing, have worked in web development, and have interned in traditional manufacturing and design & prototyping.
                    </p>
                    <p>
                        I thrive in a multitasking, cooperative environment and have experience interfacing with clients and vendors at all stages of deployment.
                    </p>
                    <p>
                        Come see some of <Link to="/projects">my projects</Link> or get down to brass tacks with <Link to="/cv">my CV</Link>. Please enjoy the website and CV builder I have built using React.
                    </p>
                    <p>
                        Thank you,<br />
                        ZYG
                    </p>
                </div>
            </div>
        </div>
        );
    }
}

function slideshowStart() {
    
    let height = 0;
    let parent = null as unknown as HTMLElement;
    let homeItems = null as unknown as HTMLElement[];
    let classNames = ["HomeItem", "HomeItemAppend"];
    for (let i = 0; i < 2; i++) {
        const items = Array.from(document.getElementsByClassName(classNames[i])) as HTMLElement[];
        if (!homeItems) homeItems = items;
        for (const item of items) {
            if (!parent) parent = item.parentNode as HTMLElement;
            height = Math.max(height, item.offsetHeight);
        }
    }
    height = window.innerHeight - 62 - 56;
    if (parent) {
        parent.setAttribute("data-height", parent.style.height);
        parent.style.height = height + "px";
        parent.setAttribute("data-child-height", height + "px");
    }
    slideshowNext(homeItems);
}

function slideshowNext(homeItems: Element[]) {
    const nextItem = homeItems.shift() as HTMLElement;
    nextItem.className = "HomeItem-all HomeItem-left-enter";
    window.setTimeout(() => {
        if (nextItem) {
            nextItem.classList.remove("HomeItem-left-enter");
            nextItem.classList.add("HomeItem-left-active");
        }
    }, transitionTime);
    window.setTimeout(() => {
        if (nextItem) {
            nextItem.classList.remove("HomeItem-left-active");
            nextItem.classList.add("HomeItem-left-exit");
        }
    }, transitionTime + viewTime);
    window.setTimeout((function(nextItem, exit) {
        return function() {
            if (nextItem) nextItem.className = "HomeItem"
            if (exit) {
                slideshowExit();
            }
        }
    })(nextItem, homeItems.length === 0), transitionTime * 2 + viewTime);
    if (homeItems.length > 0) {
        window.setTimeout((function(homeItems) {
            return function() {
                slideshowNext(homeItems);
            };
        })(homeItems), transitionTime + viewTime);
    }
}

function slideshowExit() {
    // Prepare transitions
    let height = 0;
    let parent = null as unknown as HTMLElement;
    let classNames = ["HomeItem", "HomeItemAppend"]
    for (let i = 0; i < 2; i++) {
        let items = document.getElementsByClassName(classNames[i]);
        for (let item of Array.from(items) as HTMLElement[]) {
            if (!parent) {
                parent = item.parentNode as HTMLElement;
                height = parseInt(parent.getAttribute("data-height") || "500");
            }
            item.className = "HomeItem-all HomeItem-top-enter";
            item.style.top = -(height / 2) + "px";
        }
    }

    // Initiate transitions
    window.setTimeout((function(parent) {
        return function() {
            let height = 0;
            let items = document.getElementsByClassName("HomeItem-all");
            for (let item of Array.from(items) as HTMLElement[]) {
                item.classList.add("HomeItem-top-active");
                
                item.style.top = (height) + "px";
                height += item.offsetHeight;

                item.classList.remove("HomeItem-top-enter");
            }
            
            if (parent) {
                parent.style.height = height + "px";
                parent.style.overflow = "visible";
                calculatePageHeight();
            }
        }   
    })(parent), 0);

    // Transitions complete, return to position: relative
    window.setTimeout((function(parent) {
        return function() {
            let items = document.getElementsByClassName("HomeItem-all");
            Array.from(items).map((item: Element) => (item as HTMLElement).className = ".HomeItem-exit");

            if (parent) parent.style.height = "";
        }
    })(parent), transitionTime * 2);
}